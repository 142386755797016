
.navbar{
  
  width: 100%;
  height: 6%;
  z-index: 1000;
  display: flex;
  position: fixed;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 12px 34px rgba(161, 161, 161, 0.329);
  font-family: var(--Font-family);
  font-size: calc(.8vh + .8vw);
  font-weight: var(--nav-font-weight);
  /* overflow: hidden; */
}

.navbar-logo{
  width: 20%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  font-size: calc(1.2vw + 1.2vh);
  font-weight: 600;

  /* background-color: cornflowerblue; */

}

.navbar-logo img{
 width: 30%;
 margin-top: calc(.1vh + .1vw);
 margin-left: 30%;
}

.navbar-menu{ 
  width:80%;
  height: 100%;
  /* margin-top: .5vh; */

  display: flex;
  text-align: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  
  /* background-color: cadetblue; */

}

.nav-menu{
  width: 99%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;

  /* background-color: burlywood; */

}

.nav-item{
  width: 10%;
  height: 4vh;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: .25%;

  /* background-color: lightcoral; */

}

.menu-icon {
    display: none;
}

.nav-links-mobile {
    display: none;
}

/* a.active {
  border-bottom: 0.25rem solid  var(--color--blue);
} */

.nav-links {
  color: rgb(36, 35, 35);
  display: flex;
  text-decoration: none;
  text-align: center;
  justify-content: center;

}
.dropdown__menu{
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown_product_menu{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.nav-links i  {
  margin-left: 1rem;
}
.nav-links:hover {

  border-bottom: 0.188rem solid var(--color--blue);
  color: var(--color--blue);

}

.fa-solid{
 left: 0;
}

.fa-circle-chevron-down{
  font-size: 1.2rem;
  margin-top: 0;
}
.fa-circle-chevron-down:hover{
  color: var(--color--blue);
}

.dropdown__about__menu{
  position: absolute;
  list-style: none;
  background-color: #ececec;
  top: calc(100% - 1.6vh);
  text-align: start;
  border-radius: 1rem 0 0 0;
  padding-right: 2%;
  width: 20rem;
  height: max-content;
  display: none;
}

.dropdown__about__menu_active{
  position: absolute;
  list-style: none;
  background-color: #ececec;
  top: calc(100% - 1.6vh);
  text-align: start;
  border-radius: 1rem 0 0 0;
  padding-right: 2%;
  width: 20rem;
  height: max-content;
}

 .dropdown__product__menu{
  position: absolute;
  list-style: none;
  background-color: #ececec;
  text-align: start;
  top: calc(100% - 1.6vh);
  border-radius: 1rem 0 0 0;
  padding-right: 2%;
  width: 20rem;
  height: max-content;
  display: none;
 }

 .dropdown__product__menu_active{
  position: absolute;
  list-style: none;
  background-color: #ececec;
  top: calc(100% - 1.6vh);
  text-align: start;
  border-radius: 1rem 0 0 0;
  padding-right: 2%;
  width: 20rem;
  height: max-content;
 }

 .dropdown__menu__item{
  width: 20rem;
  padding: 5%;
  padding-right: 5%;
}

.dropdown__menu__item:hover{
  background-color: var(--background--color-hover);
  color: #ffffffc4;
  border-radius: 1rem 0 0 0;
  padding-right: 5%;
  width: 20rem;

}

.userMenu{
  width: 7%;
  height: 100%;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  /* background-color: burlywood; */
}

.userMenus{
  font-family: var(--Font-family);
}

#account-menu{
  font-family: var(--Font-family);
}

.MenuItem{
  font-size: 1rem;
  font-family: var(--Font-family);
}

/* @media screen and (min-width: 960px) and (max-width: 960px) { */
  /*  960p */
  @media screen and (max-width: 960px) {
  .navbar{
    width: 100%;
    height: 8vh;
    position: fixed;
  }
  .iconLogin{ 
    display: none;
  }
  .navbar-logo{
    /* background-color: cornflowerblue; */
    width: 100%;
    position: absolute;
    font-size: calc(2vw + 2vh);
    font-weight: 600;
  }
  .nav-menu{
    display: none;
  }
  .userMenu{
    display: none;
  }

  .menu-icon {
    width: 5vw;
    height: 8vh;
    display: flex;
    position: absolute;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    color: rgb(0, 0, 0);
    margin-left: 5vw;

  }
  .fas{
    color: rgb(0, 0, 0);

  }
  .fa-bars{
    color: rgb(0, 0, 0);
    font-size: 3rem;
    right: 4rem;
  }
  .fa-times {
    color: rgb(255, 255, 255);
    font-size: 3rem;
    right: 4rem;
    z-index: 999;
  }

  .fa-times:hover {
    color: var(--color--blue);
  }

  .fa-circle-chevron-down{
    display: none;
  }
  .nav-menu.active {
    width: 105vw;
    height: 105vh;
    background-color: rgba(0, 0, 0, 0.904);
    display: flex;
    position: absolute;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    font-size: calc(7vw - .5rem);
    z-index: 998;
  }
  .nav-item{
    width: 50vw;
    height: 5vh;
    flex-direction: column;
    margin-bottom: 1.6rem;
    margin-top: 1rem;
}
  .nav-links {
    width: 50vw;
  }
  .navbar-menu li a{
    text-decoration: none;
    color: #fff;
  }
  a.active {
    border-bottom: 0.2rem solid var(--color--blue);
    padding-bottom: 0.3em;
    width: max-content;
  }

  .userMenu{
    width: max-content;
    margin-left: calc(5vw + 5vh);
    z-index: 999;
    /* background-color: cornflowerblue; */
  }

}