:root{
  --Font-family: 'FC Iconic','Kanit','sans-serif';
  --nav-font-size: 1.4rem;
  --nav-font-weight: 600;
  --nav-dropdown-font-size : .5rem;;

  --text-color: #3a3a3a;
  --color: #9176FF;
  --bg-color: #f4f4f4;
  --color--blue: #0087E7;

  --font--size--head: 6vmin;
  --font--size--body: 3vmin;

  --background--color-hover:#0087E7;

  --font--size--icon: 3rem;

  --dropdown--about: none;

  --color--icon--menu: #d7d7d7c5;
  --color--icon--menu--hover: #d7d7d7c5;
  --color--menu--item--hover: #0087E7;
  --color--menu--item--background--hover: #e9e9e9;
}


*{
  margin: 0;
  padding: 0;
  font-family: var(--Font-family);
}

html,
body {
  width: 100%;

  margin: 0;
  padding: 0;
}

img{
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
}

a {
    text-decoration: none;
    color: var(--text-color);
}
ul {
  font-family: var(--Font-family);
}
li{
  font-family: var(--Font-family);
}

button {
  font-family: var(--Font-family);
  font-size: 2rem;
}

Button {
  font-family: var(--Font-family);
} 

input{
  font-family: var(--Font-family);
}

#outlined-textarea, 
#outlined-textarea-label, 
#demo-simple-select-label, 
#MenuItem,
#outlined-basic-label,
#demo-simple-select ,
#outlined-basic,
#text,
#outlined-adornment-password
{
  font-family: var(--Font-family);
  font-size: 1.5rem;
}

#Button{
  /* margin: 5%; */
  margin-top: 10%;
  width: 100%; 
  height: 4rem; 
  font-size: 25px;
  color: '#fff';
  font-family: var(--Font-family);
}

#Buttons{
  width: 100%; 
  height: 4rem; 
  font-size: 25px;
  color: '#fff';
  font-family: var(--Font-family);
}