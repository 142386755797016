.salesetting{
    /* width: calc(100% - 80px);
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    position: absolute;
    font-size: 1.5rem;
    background-color: rgba(211, 211, 212, 0.477);
    z-index: 800; */

    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: relative;
    font-size: 1.5rem;
    background-color: rgba(211, 211, 212, 0.477);
    z-index: 800;
}

.sale_body{
    width: 90%;
    height: 85%;

    background-color: #fff;

    border-radius:  2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .789%;
    margin-top: 4%;
}

.sale_body label{
    margin-right: 5%;
    width: 8rem;
}

.sale_package{
 width: 50%;
 height: 90%;
 /* background-color: burlywood; */

}

.sale_customer{
 width: 50%;
 height: 90%;
 /* background-color: cadetblue; */

}

