.package{
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    /* margin-left: 15vw; */
    position: relative;
    font-size: 1.5rem;
    background-color: rgba(211, 211, 212, 0.477);
    z-index: 800;
}

.package_header{
    width: 90%;
    height: 5rem;

    display: flex;
    flex-direction: row;

    position: relative;

    margin-top: 4%;
}

.btn-export{
    width: 80%;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.text-search{

    width: 30%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.package_body{
    width:90%;
    height: 86%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tHeader{
 background-color: cornflowerblue;

}

.tHeader_row{

}

.tHeader_row_cell{
 color: #fff;
}


.tBody{

}

.tBody_row{
    width: 100%;
    height: 3rem;
}

.tBody_cell{
    height: 3rem;
}




.package_bottom{
    width: 90%;
    height:10%;
}
.package_bottom_page{
 font-family: var(--Font-family);
}