.pos{
  width: 90%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10% 5% 5% 5%;
}
  


.pos__header{
    font-size: var(--font--size--head);
    display: flex;
    width: 100%;
}

.pos__body{

}

.pos__bottom{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pos__bottom__head{
    font-size: var(--font--size--head);
    display: flex;
}

.pos__bottom__body{

}

.mySwiperPos{
    margin: 0;
    padding: 5%;
}

.swiper-container {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: left;
    
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper-container {
    width: 100%;
    height: 300px;
    margin: 20px auto;
  }

  .append-buttons {
    text-align: center;
    margin-top: 20px;
  }
  
  .append-buttons button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #007aff;
  color: #007aff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 13px;
  }
  
  .pos--item{
    margin-bottom: 2%;
    padding: 4%;
   
  }
  
  .pos--item:hover {
    transform: scale(2);
    margin: 6%;
    z-index: 999;
  }

  .pos__bottom__bottom{
    font-size: var(--font--size--head);
    justify-content: center;
    align-items: center;
  }

  .text_package{
    color: var(--color--blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .btn--package{
    width: calc(20vh + 20vw);
    height: max-content;
    border-radius:  2rem 0 0 0;
    background: '#3985e3';
    color: '#ffffff';
    /* font-size: 4rem; */
    font-size: max(2vw, 30px);
    font-family: var(--Font-family);
    padding-bottom: 1rem;
    /* margin-left: 30%; */
  }

  .btn--package:hover{
    background-color: var(--text-color);
    color: var(--bg-color);
    cursor: pointer;
}

@media screen and (max-width: 960px) {
  .pos{
    margin-top: 10%;
  }
  
}