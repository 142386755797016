.mrp_buy{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    justify-items: center;
    align-items: center;
    margin-top: 5%;
    font-family: var(--Font-family);
}

.mrp_buy_header{
    width: 100%;
    height: 50%;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    /* margin-top: calc(5vh + 5vw); */
}
.textHeader{
    margin-top: 5%;
    font-size: calc(2vh + 2vw);
}

.text-input{
    width: 70%;
    margin-top: 12%;
}
.TextField{
    width: 100%;
}

.mrp_buy_body{
 display: flex;
 flex-direction: row;

 width: 80%;
 height: 100%;
}

.mrp_buy_body__left{

 width: 50%;
 height: 100%;

 display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

}

.mrp_buy_body__right{
    width: 50%;

    display: flex;
 flex-direction: column;

 justify-content: center;
 align-items: center;

}
.googlemap{

    width: 80%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mrp_buy_bottom{
    margin-bottom: 5%;
}

.mrp_textAddress{
    font-size: max(1.4vw, 16px);
}

.mrp_textPhone{
    font-size: max(1.4vw, 16px);
}

@media screen and (max-width: 960px) {
    .mrp_buy{
    
    }

    .mrp_buy_body{
        display: flex;
        flex-direction: column;
       
        width: 100%;
        height: 100%;
    }
       
    .mrp_buy_body__left{
        width: 100%;
    }
       
    .mrp_buy_body__right{
        width: 100%;
    }

    .mrp_textAddress{
        width: 80%;
        height: 100%;
        font-size: calc(2vh + 1.5vw);
    }
    
    .mrp_textPhone{
        width: 100%;
        font-size: calc(2vh + 1.5vw);

        display: flex;
        justify-content: center;
        align-items: center;
    }

}