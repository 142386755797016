.xcom{
    width: 90%;
    height: max-content;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5% 5% 5% 5%;
}

.xcom-header{
    font-size: var(--font--size--head);
    display: flex;
    width: 100%;
    margin-top: 5%;
}

.xcom-body{
 font-size: var(--font--size--body);
}

.xcom-body p{
    text-indent: 50px;
}
.xcom-body ul{
    margin-top: 1rem;
}
.xcom-body li{
    text-indent: 40px;
    margin-top: 1rem;
    margin-left: 8rem;
    margin-right: 8rem;
    text-align: left;
    width: 90%;
}

.xcom-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}