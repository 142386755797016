
.Organization{
    width: 80%;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    margin-top: 5%;
}

.Organization_body{
    
}