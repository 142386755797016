.user{
    width: calc(100% - 15vw);
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin-left: 15vw;
    position: relative;
    font-size: 1.5rem;
    font-family: var(--Font-family);
}

.user_body{
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

}

.user_body_card{
    width: 10rem;
    height: 10rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0087e7;
    margin: 50px;
    cursor: pointer;
    border-radius: 20%;
}

.user_body_card:hover {
    background-color: #3078ab;
    color: var(--color--menu--item--background--hover); 
}

.user_body_card:hover  icon{
    background-color: #3078ab;
    color: var(--color--menu--item--background--hover); 
}

.user_setting_body{
    width: 100%;
    height: 100%;
    background-color: burlywood;

    display: flex;
    flex-direction: row;
}

.user_setting_body_list_user{
    width: 30%;
    height: 80%;
    background-color: brown;

    margin: 3%;

    display: flex;
    flex-direction: column;
}