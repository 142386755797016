.pms{
    width: 90%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10% 5% 5% 5%;
}

.pms-header{
    width: 100%;
    font-size: var(--font--size--head);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.pms-body{
 font-size: var(--font--size--body);
}

.pms-body p{
    text-indent: 50px;
}
.pms-body ul{
    margin-top: 1rem;
}
.pms-body li{
    text-indent: 40px;
    margin-top: 1rem;
    margin-left: 8rem;
    margin-right: 8rem;
    text-align: left;
    width: 90%;
}

.pms-bottom {
    width: 100%;
    height: max-content;

    margin-top: 10%;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

}

.pms-bottom--img{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 960px) {
    .pms{
      margin-top: calc(5vh + 10vw);
    }
    .pms-body li{
        text-indent: 40px;
        margin-left: 3rem;
    }
}