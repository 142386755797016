.dropdown-menu-about{
    width: auto;
    height: fit-content;
    list-style: none;
    margin: 0;
    padding: 0rem;
    font-size: 1.5rem;
    z-index: 200;
    position: absolute;
}

.dropdown-menu-about li {
    background: #ececec;
    cursor: pointer;
    margin: 0rem;
}

.dropdown-menu-about li a{
    text-decoration: none;
    text-align: left;
  }

.dropdown-menu-about li:hover{
    background: var(--background--color-hover);
    color: #ececec;
    width: auto;
    height: inherit;
}

.dropdown-menu-about li a:hover{
    background: var(--background--color-hover);
    color: #ffffffc4;
    width: auto;
    height: inherit;
}

.dropdown-menu-about.clicked{
    display: none;
}

/* .dropdown-link{
    display: block;
    text-decoration: none;
    padding: 0rem;
} */

@media screen and (min-width: 960px) and (max-width: 960px) {
    .dropdown-menu-about{
        width: 14rem;
        position: absolute;
        top: 12rem;
        right: 1rem;
        list-style: none;
        text-align: start;
        padding: 0;
        margin: 5rem;
    }
    
    .dropdown-menu-about li {
        cursor: pointer;
    }
    
    .dropdown-menu-about li:hover{
    }
    
    .dropdown-menu-about.clicked{
        display: none;
    }
    
    .dropdown-link{
        display: block;
        width: 100%;
        height: 105%;
        text-decoration: none;
        padding: 1rem;
    }
}

@media screen and (min-width: 1080px) and (max-width: 1080px) {
    /* 1080px */
    .dropdown-menu-about{
        left: 40.5%;
        top: 4rem;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1600px) {
    /* 1600px */
    .dropdown-menu-about{
        left: 53%;
        top: 4.3rem;
    }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
    /* 1920px */
    .dropdown-menu-about{
        left: 57%;
        top: 4rem;
    }
}

@media screen and (min-width: 2560px) and (max-width: 2560px) {
    /* 2560px 2K */
    .dropdown-menu-about{
        left: 57.5%;
        top: 5.5rem;
        font-size: 2rem;
        width: auto;
    }
}
@media screen and (min-width: 3840px) and (max-width: 3840px) {
    /* 3840px 4K */
    .dropdown-menu-about{
        left: 25%;
        top: 5rem;
        font-size: 3rem;
        width: auto;
    }
  }