.erp{
    width: 100%;
    height: max-content;
    
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    /* margin: 10% 10% 5% 10%; */

    margin-top: 5%;
}

.erp__header{
    width: 90%;
    font-size: var(--font--size--head);
    display: flex;
    flex-direction: row;

    justify-content: left;
    align-items: center;
    
}

.erp__body{
    width: 90%;
  
    display: flex;
    flex-direction: row;
  
    justify-content: left;
    align-items: center;

}

.erp__bottom{

}

.btn--erp{
    font-size: 3rem;
    background-color: rgb(77, 77, 77);
    /* margin-left: 30%; */
    font-weight: 600;
    font-family: var(--Font-family);

    width: calc(20vh + 20vw);
    height: max-content;
    border-radius:  2rem 0 0 0;
    color: '#ffffff';
    /* font-size: 4rem; */
    font-size: max(2vw, 30px);
    padding-bottom: 1rem;
    /* margin-left: 30%; */
}
.btn--erp:hover{
    background-color: var(--background--color-hover);
}

@media screen and (max-width: 960px) {
    .erp__header{
        margin-top: 10%;
    }
}