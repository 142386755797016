.dropdown-menu-product{
    width: auto;
    height: fit-content;
    position: absolute;
    top: 2.2rem;
    left: 500%;
    list-style: none;
    padding: -1rem;
    margin: 0rem;
    font-size: 1.5rem;
    z-index: 200;
}

.dropdown-menu-product li {
    background: #ececec;
    cursor: pointer;
    margin: 0rem;
}

.dropdown-menu-product li a{
    text-decoration: none;
    text-align: left;
}

.dropdown-menu-product li:hover{
    background: var(--background--color-hover);
    width: auto;
    height: inherit;
}

.dropdown-menu-product li a:hover{
    background: var(--background--color-hover);
    color: #ffffffc4;
    width: auto;
    height: inherit;
}

.dropdown-menu-product.clicked{
    display: none;
}

.dropdown-link{
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: rgb(0, 0, 0);
    padding: 1rem;
}

@media screen and (min-width: 960px) and (max-width: 960px) {
    .dropdown-menu-product{
        width: 14rem;
        position: absolute;
        top: 105%;
        list-style: none;
        text-align: start;
        padding: 0;
        margin: 0;
    }
    
    .dropdown-menu-product li {
        background: #ececec6f;
        cursor: pointer;
    }
    
    .dropdown-menu-product li:hover{
        background: #ececec6f;
        color: rgb(255, 255, 255);
    }
    
    .dropdown-menu-product.clicked{
        display: none;
    }
    
    .dropdown-link{
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: #fff;
        padding: 1rem;
    }
}

@media screen and (min-width: 1080px) and (max-width: 1080px) {
    /* 1080px */
    .dropdown-menu-product{
        left: 51.4%;
        top: 4rem;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1600px) {
    /* 1600px */
    .dropdown-menu-product{
        left: 61.5%;
        top: 4.3rem;
    }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
    /* 1920px */
    .dropdown-menu-product{
        left: 63.8%;
        top: 4rem;
    }
}

@media screen and (min-width: 2560px) and (max-width: 2560px) {
    /* 2560px 2K */
    .dropdown-menu-product{
        left: 65%;
        top: 5.3rem;
        font-size: 2rem;
        width: auto;
    }
}
@media screen and (min-width: 3840px) and (max-width: 3840px) {
    /* 3840px 4K */
    .dropdown-menu-product{
        left: 32.6%;
        top: 5.2rem;
        font-size: 3rem;
        width: auto;
    }
  }