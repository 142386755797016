
.Footer{
    width: 100%;
    height: 870px;
    
    bottom: 0;
    z-index: 100;
    color: var(--text-color);
    box-shadow: 0 0 34px rgba(161, 161, 161, 0.329);
    background-color: rgba(194, 194, 194, 0.247);

    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
}

.Footer-1{
    width: 50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    font-size: calc(1vh + 1vw);

    /* background-color: cornflowerblue; */
}

.Footer-1--text{
    margin: 1% 1%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.Footer-2{
    width: 10%;
    height: 100%;
    bottom: 0;
    
    
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    font-size: calc(1vh + 1vw);
    text-align: center;

    /* background-color: burlywood; */
}

.Footer-2-text{
    margin-top: 50%;
    margin-left: 15%;
    text-align: left;
}

.Footer-3{
    width: 50%;
    height: 100%;

    /* background-color: cadetblue; */

    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    font-size: calc(1vh + 1vw);
    text-align: start;

    margin-left: 5%;
    
}
.Footer-3-text{
    margin-top: 5%;
}

.Footer-4{
    width: max-content;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: green; */
}
.Footer-4--icon{
    display: flex;
    align-content: center;
    justify-content: center;

    margin-top: 100%;
    /* background-color: burlywood; */
}
.Footer-4--icon--item{

}

.Footer-5{
    width: 20%;
    height: 100%;
    right: 0;;

    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 40%; */
    /* background-color: goldenrod; */
}

.Footer-5--QR{
    width: 60%;
    height: 20%;

    display: flex;
    align-content: center;
    justify-content: center;

}

.line{
    width: 3px;
    height: 25vh;

    margin-left:50px;
    margin-top: 20px;
    background-color: rgb(97, 97, 97);
}

/* .qrcode:hover{
    width: 50vw;
    height: 70vh;
    position: absolute;

    top: 5%;
    left: 25%;
} */


@media screen and (max-width: 960px) {
    .line{
        display: none;
    }

    .Footer{
        width: 100%;
        height: max-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
        z-index: 0;
    }

    .Footer-1{
        width: 100%;
        height: max-content;
        flex-direction: column;
        margin-top: 5%;
        
    }

    .Footer-2{
        width: 90%;
        height: max-content;
        bottom: 0;
        
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 5%;
        font-size: calc(1vh + 1vw);
    }

    .Footer-2-text{
        margin-top: 0;
        margin-left: 10%;
    }

    .Footer-3{
        width: 100%;
        height: max-content;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: calc(1vh + 1vw);
        margin-top: 5%;

    }
    .Footer-3-text{
        margin-top: 2%;
    }
    

    .Footer-4{
        width: 100%;
        height: max-content;
        flex-direction: row;
        margin-top: 5%;
    }

    .Footer-4--icon{
        display: flex;
        align-content: center;
        justify-content: center;
        margin-top: 0;
        margin-left: 10%;
        margin-bottom: 5%;

    }

    .Footer-5{
        display: none;
    }
}