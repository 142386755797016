.Setting_page{
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    /* margin-left: 15vw; */
    position: relative;
    font-size: 1.5rem;
    background-color: rgba(211, 211, 212, 0.477);
    z-index: 800;
}
.Setting_body{
    margin-top: 4%;
    margin-left: 10%;
}