.founder{
    width: 100%;
    margin-bottom: 5%;

}

.founder-img{
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
}

.founder-img img{
    width: 30%;
    margin: 2%;
    /* height: 40%; */
}

@media screen and (max-width: 960px) {

    .founder{
        
    }
    .founder-img{
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
    }
    .founder-img img{
        width: 70%;
        margin-top: 5%;
    }
}