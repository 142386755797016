.pos_buy2{
    width: 100%;

    display: flex;
    flex-direction: column;

    justify-items: center;
    align-items: center;
    font-family: var(--Font-family);
    /* background-color: bisque; */
    overflow: hidden;
}


.pos_buy2_header{
    width: 100%;
    height: 5rem;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    /* margin-top: calc(5vh + 5vw); */

    /* background-color: darkred; */
}
.textCheck{
 display: none;
}

.textCheck.active{
    display: contents;
    width: max-content;
    height: max-content;
    font-size: 1.8rem;
    color: crimson;
}

.textHeader2{
    font-size: calc(2vh + 2vw);
}

.textAddress{
    /* font-size: max(1.5vw, 16px); */
    font-size: max(1.4vw, 16px);
}
.textAddressPhone{
    font-size: max(1.4vw, 16px);
}

.text-input2{
    width: 70%;
    margin-top: 12%;
    font-family: var(--Font-family);

}
.TextField2{
    width: 100%;
    font-family: var(--Font-family);
}


.pos_buy2_body{
 display: flex;
 flex-direction: row;

 width: 100%;
 height: 80%;
 /* background-color: cornflowerblue; */
}

.pos_buy2_body__left{
 width: 30%;

 display: flex;
 flex-direction: column;

 align-items: center;
 overflow: hidden;

 padding: 2%;

 /* background-color: darkorange; */
}

.pos_buy2_body__center{
    width: 30%;
    
    display: flex;
    flex-direction: column;

    align-items: center;
    overflow: hidden;
    padding: 2%;
    /* background-color: rgb(48, 235, 104); */
}

.pos_buy2_body__right{
    width: 30%;

    display: flex;
    flex-direction: column;

    align-items: center;
    overflow: hidden;
    padding: 2%;
}
.Iframe{
    height: 400px;
}

.pos_buy2_bottom{
    /* margin-bottom: 5%; */
}


@media screen and (max-width: 960px) {
    .pos_buy2_header{
        margin-top: 5%;
    }

    .pos_buy2_body{
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
       
        width: 100%;
        height: 100%;
    }
    .pos_buy2_body__left{
        width: 90%;
        margin-left: 4%;
        /* background-color: darkorange; */
    }
    .text-input2{
        width: 100%;
        margin-top: 5%;
    }

    .pos_buy2_body__center{
        width: 90%;
        margin-left: 4%;
        /* background-color: rgb(6, 184, 15); */

    }

    .pos_buy2_body__right{
        margin-top: 5%;
        width: 90%;
        margin-left: 4%;
        /* background-color: rgb(204, 75, 182); */
    }

    .textHeader2{
        margin-top: 5%;
    }

    .textAddress{
        width: 100%;
        font-size: calc(2vh + 1.5vw);
    }

    .textAddressPhone{
        width: 100%;
        font-size: calc(2vh + 1.5vw);

        display: flex;
        justify-content: center;
        align-items: center;
    }
}