.career{
  width: 80%;
  height: max-content;
  align-items: center;
  justify-content: center;
  margin: 10% 10% 5% 10%;
}

.career-header{
  /* font-size: var(--font--size--head); */
  font-size: 3.5rem;
}
.career-body{
  margin-top: 2%;
  font-size: var(--font--size--body);
  text-indent: 5rem;
}

.career-bottom{
  margin-top: 5%;
  font-size: 2rem;
 display: none;

}

.career-card{
 width: 100%;
 height: 500px;
 /* display: flex;*/
 display: none;
}

.career-card--item{
  width: 300px;
  height: 300px;
  background-color: rgba(216, 208, 208, 0.445);
  margin: 2%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: var(--font--size--body);
}

@media screen and (max-width: 960px) {
  .career{
    margin-top: 15%;
  }
  .career-header{
    font-size: 2rem;
    text-justify: auto;
  }
}