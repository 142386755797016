.user_setting{
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    /* margin-left: 15vw; */
    position: relative;
    font-size: 1.5rem;
    background-color: rgba(211, 211, 212, 0.477);
    z-index: 800;
}

.bar{
    width: 99%;
    height: 3rem;

    display: flex;

    justify-content: start;
    align-items: center;
    padding: .5%;

    background-color: var(--color--blue);
}

.bar-link{
    cursor: pointer;
    color: #fff;
    margin-right: 1%;
}
.bar-link:hover{
    cursor: pointer;
    color: #9f9f9f;
}