
.Symbol{
    width: 90%;
    align-items: center;
    justify-content: center;
    margin: 10% 5% 5% 5%;
}
/*#region Symbol__header*/
.Symbol__header{
    font-size: var(--font--size--head);
    width: 100%;
  
  display: flex;
  flex-direction: row;

  justify-content: left;
  align-items: center;
}
/*#endregion Symbol__header*/


/*#region Symbol__body*/
.Symbol__body{
    margin-top: 5%;
}
.Symbol__body__h1{
 font-size: var(--font--size--body);
}
.Symbol__body__logo{
    width: 70%;
    height: 70%;
    margin: 5%;
}

.Symbol__body__text{
    margin-top: 5%;
    font-size: var(--font--size--body);
}
.text_p{
    text-indent: 5%;
}
/*#endregion Symbol__body*/


@media screen and (max-width: 960px) {

    .Symbol__body__logo{
        width: 100%;
        display: flex;
        flex-direction: column;
        
        justify-content: center;
        align-items: center;
    }

    .Symbol__body__text{
        display: flex;
        flex-direction: column;
        
        justify-content: center;
        align-items: center;

        text-align: left;
        text-indent: 30px;
    }

}


