
.vision{
    width: 90%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
    margin-top: 10%;
    /* background-image: url('./bg_img.jpg'); */
    background-size: 100% 100%;
    /* background-color: #3985e3; */
}

.vision--head{

    width: 100%;


    display: flex;
    flex-direction: row;

    justify-content: left;
    align-items: center;
}

.box{
    width: 15px;
    height: 40px;
    background-color: #3985e3;
    margin-top: 0%;
    margin-right: 1%;
}
.texthead{
    font-size: var(--font--size--head);
}
.vision--body{
    font-size: var(--font--size--body);
    
}
.vision--buttom{
    width: 100%;
    

    display: flex;
    flex-direction: row;

    justify-content: left;
    align-items: center;
}
.--buttom--head{
    font-size: var(--font--size--head);
}

.--buttom--body{
    font-size: var(--font--size--body);
    text-indent: 50px;
    margin-bottom: 10%;
}

@media screen and (max-width: 960px) {
    .box{
        /* margin-top: 2%; */
    }
}

