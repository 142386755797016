.home{
    width:  100%;
    overflow: hidden;
    /* scroll-snap-align: center; */
    display: flex;
    flex-direction: column;
}

.home-head{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 5%;
    z-index: 1;
    /* position: relative; */
}

.text__headerI{
    /* font-size: calc(5vh + 5vw); */
    font-size: max(6vw, 60px);
    font-weight: 600;
    color: var(--color--blue);
    margin-left: 5%;
  }
  .text__headerII{
    /* font-size: calc(4vh + 4vw); */
    font-size: max(5vw, 50px);

    font-weight: 500;
    color: var(--text-color);
    margin-left: 5%;

  }

  .w600 { 
     font-size: 1rem;
    font-weight: 800;
    margin-left: 50px;
  }

.home-body{
    width: 100%;
    height: max-content;
    margin-top: .5%;
    /* margin-left: 5%; */
}
/*#region Slide*/
.mySwiper__home{
    width: 97vw;
    height: 67vh;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    overflow: hidden;
  }
  
  .swiper-slide {
    /* overflow: hidden; */
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .card__home{
    width: 100%;
    height: 90%;
    display: block;
    overflow-x: hidden;
    cursor: pointer;
  }

  .card__home img{
    padding: 1fr;
  }
  
  .swiper-container {
    width: 100%;
    height: 300px;
    margin: 20px auto;
  }

  .append-buttons {
  text-align: center;
  margin-top: 20px;
  }
  
  .append-buttons button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #007aff;
  color: #007aff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 20px 20px;
  font-size: 1rem;
  }

.home-bottom{
    display: flex;
    justify-content: flex-end;
    text-align: end;
    flex-direction: row;
    font-size: 2rem;
}

  /*#endregion slide*/

.home__services{
   /* background-color: brown; */
}

.home__about{
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: -5rem;
}

.about-image {
    width: 60%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
}
.about--image{
    width: 70%;
    height: 90%;
    position: absolute;

    display: flex;
    align-content: center;
    justify-content: center;

}

.about--image img{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.about--text{
    width: 40%;
    height: 100%;
    padding: 2%;
}


.about--text--h1{
    /* font-size: 6rem; */
    font-size: calc(5vh + 5vw);
    font-family: var(-Font-family);
    font-weight: 600;
    margin-top: 30%;
}

.about--text--p{
    font-size: calc(1vh + 1vw);
    text-indent: 40px;
    text-align: start;
    text-align: justify;
}

.about--text--btn{
    margin-top: 5%;
}

.home__contact{
    width: 100%;
    height: 40vh;
    
    margin-top: 5%;
    margin-bottom: 10%;

    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    background-color: #f3f3f3d3;
}

._contact1{
    width: 50%;

    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.__text1{
    width: 100%;
    height: min-content;

    font-size: calc(3vh + 3vw);

    display: flex;
    align-content: center;
    justify-content: center;
}

.__text2{
    font-size: calc(3vh + 3vw);
    width: 100%;
    height: min-content;

    color: var(--color--blue);

    display: flex;
    align-content: center;
    justify-content: center;
}

._contact2{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(1vh + 1vw);
}


.__text4{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
    

}
.__text4-btn{
    width: 20vw;
    height: max-content;
    display: flex;
    /* position: absolute; */
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 960px) {
    .home{
        margin-top: 10%;
    }
    .home-head{
        align-items: center;
    }

    .about--image img{
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    .home__about{
        flex-direction: column;
        /* background-color: #007aff; */
    }

    .about-image{
        /* background-color: burlywood; */
    }

    .about--image{
        /* background-color: brown; */
    }

    .about--text{
     /* background-color: darkkhaki; */
    }
    .about--text--h1{
        margin-top: 5%;
    }

    .home__contact{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }
    ._contact2{
        margin-top: 5%;
    }
/* 
      .card__home{
        width:  min(60%);
        height: 90%;
        display: block;
      } */
}

