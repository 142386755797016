.navAdmin{
  width: 15vw;
  height: 100vh;

  position: absolute;
  font-family: var(--Font-family);
  font-size: var(--nav-font-size);
  font-weight: var(--nav-font-weight);
  z-index: 900;
  background-color: #ffffff;
  transition: 3s;
  transform: 3s;
}
.navAdmin_click{
    max-width: 64px;
    min-width: 64px;
    height: 100%;
    position: absolute;
    z-index: 900;
    font-family: var(--Font-family);
    font-size: var(--nav-font-size);
    font-weight: var(--nav-font-weight);
    background-color: #ffffff;
    transition: 3s;
    transform: 3s;

}


.icon_menuBar{
    cursor: pointer;
    position: relative;
}

.iconMenu_ul{
 width: 100%;
 position: relative;
}

.iconMenu_li{
    position: relative;
    width: 50%;
    height: 20px;
    padding: 1rem;
    
    margin-top: 4%;
    border-bottom: 0.825px solid var(--color--blue);
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconMenu_icon{
    margin-bottom: 2%;
}

.navAdmin_body{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.navAdmin_menu{
    width: 100%;

}

.navAdmin_menu_bar{
    width: 100%;
    height: 5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.navAdmin_user{
    width: 100%;
    height: 5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    color: #ffffff;
    font-family: var(--Font-family);
    font-size: 3vw;
    font-weight: var(--nav-font-weight);

    cursor: pointer;
}


.navAdmin__menu{
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    margin-top: 5%;
}

.navAdmin__menu__item__icon{
    /* background-color: var(--color--icon--menu); */
    border-radius: 45%;
}

.navAdmin__menu__item{
    width: 100%;
    height: 3rem;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: relative;
    border-bottom: 1px solid #E8E8E8;
    padding: 0px 0px 0px 20px;
    gap: 25px;
    overflow: hidden;
}

.navAdmin__menu__item.active{
    width: 90%;
    height: 3rem;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: relative;
    background-color: var(--color--menu--item--background--hover);
    border-bottom: 1px solid var(--color--blue);
    color: var(--color--menu--item--hover);
    border-radius: 10px;
    gap: 25px;
    margin-left: 1%;
    overflow: hidden;
}
.navAdmin__menu__item.active text{
    color: var(--color--menu--item--hover);
}

.navAdmin-link.active{
    color: var(--color--menu--item--hover);
}
.navAdmin-link.active li{
    color: var(--color--menu--item--hover);
}

.navAdmin__menu__item:hover{
    cursor: pointer;
    background-color: var(--color--menu--item--background--hover);
    color: var(--color--menu--item--hover);
    border-radius: 10px;
    width: 92%;
    overflow: hidden;
}

.navAdmin__menu__item:hover text{
    cursor: pointer;
    /* background-color: var(--color--menu--item--background--hover); */
    color: var(--color--menu--item--hover);
}

.navAdmin__menu__item__icon:hover{
    /* background-color: var(--color--icon--menu--hover); */
    color: var(--color--menu--item--hover);
}

.navAdmin__menu__item__name{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.navAdmin__menu_logout{
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;

    margin-bottom: 10%;
}
.setting.active{
    width: 100%;
    height: 3rem;
    margin: 2% 0 2% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E8E8E8;
    cursor: pointer;
}

.setting.active:hover{
    background-color: rgba(150, 181, 255, 0.474);
}

.setting{
    display: none;
}

@media screen and (max-width: 960px) {

    .navAdmin{
        max-width: 80px;
        min-width: 80px;
        height: 100%;
        position: fixed;
    }

    .navAdmin_user_img{
        min-width: 40%;
        height: 90%;
        top: -15px;
        display: flex;
        position: relative;
    }

    .navAdmin__menu__item{
        justify-content: center;
        align-items: center;
        border-radius: 40px;
    }

    .navAdmin__menu__item__name{
        display: none;
    }
}

