.resume{
    width: 90%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
    font-variant: 40%;
    margin-top: 10%;
    margin-bottom: 5%;
}

.mySwiper--resume{
    width: 100%;
  }
  .swiper-container {
    width: 100%;
    height: 100%;

  }
  
  .swiper-slide {
  text-align: left;
  
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper-container {
    width: 100%;
    height: 300px;
    margin: 20px auto;
  }
  .append-buttons {
  text-align: center;
  margin-top: 20px;
  }
  
  .append-buttons button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #007aff;
  color: #007aff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 13px;
  }
  
  .card--resume{
    margin-bottom: 6%;
    padding: 0;
  }
  
  .card--resume:hover {
  background-color: #007bff38;
  color: #fff;
  
  }
  
  .card--resume i:hover {
  color: #fff;
  }


.resume_data{
    
    align-items: center;
    justify-content: center;
    /* width: 80%;
    margin-bottom: 10%;
    margin-left: 10%; */
}
.resume_data_head{
  width: 100%;
  
  display: flex;
  flex-direction: row;

  justify-content: left;
  align-items: center;
}

.text_hd{
    font-size:  var(--font--size--head);
}

.text_data{
 font-size: var(--font--size--body);
 margin-top: 1rem;
}
.--p1{
 margin-top: 2%;
 text-indent: 50px;
}

.--p2{
    text-indent: 50px;
}

.--p3{
    text-indent: 50px;
}
.--p4{
    color: var(--color--blue);
}