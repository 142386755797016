.news{
    width: 80%;
    align-items: center;
    justify-content: center;
    margin: 10% 5% 10% 10%;
}

.news_header{
    width: 100%;
    height: auto;
    font-size: var(--font--size--head);
}

.news_body{
    width: 100%;
    height: fit-content;
}

.news_footer{

}

.item_img{
    margin-bottom: 2%;
}

.loading-header{
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
}
.loading-dody{
    font-size: var(--font--size--head);
    text-align: center;
    align-items: center;
    justify-content: center;
    /* margin: 10% 5% 10% 10%; */
    display: flex;
    
    width: 100%;
    height: 100%;
    padding: 1%;
    z-index: 999;
}