.hrm{
    width: 80%;
    height: max-content;
    align-items: center;
    justify-content: center;
    margin: 10% 10% 5% 10%;
}

.hrm-header{
    font-size: var(--font--size--head);
    display: flex;
}

.hrm-body{
    font-size: var(--font--size--body);
}

.hrm-body p{
    text-indent: 50px;
}
.hrm-body ul{
    margin-top: 1rem;
}
.hrm-body li{
    text-indent: 40px;
    margin-top: 1rem;
    margin-left: 8rem;
    margin-right: 8rem;
    text-align: left;
    width: 90%;
}

.hrm-bottom{

}