.mrp{
    width: 90%;
    height: max-content;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10% 5% 5% 5%;

}

.mrp-header{
    font-size: var(--font--size--head);
    width: 100%;
    display: flex;
}

.mrp-body{
    width: 100%;
 font-size: var(--font--size--body);
}

.mrp-body p{
    text-indent: 40px;
}
.mrp-body ul{
    margin-top: 1rem;
}
.mrp-body li{
    text-indent: 40px;
    margin-top: 1rem;
    width: 90%;
    margin-left: 10%;
}

.mrp-module{

}

.mrp-bottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}