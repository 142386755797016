.about{
    width: 100%;
    height: 100%;
}
.about-header{
    width: 100%;
    height: 50%;
    margin-top: 6%;


    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

}

.about-header--image{
    width: 50%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}

.about-header--image__image{
    width: 80%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;


}
.about-header--image__image img{
    width: 90%;
    align-items: center;
    justify-content: center;
}

.about--text{
    width: 50%;
    height: 100%;
    
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

.about--text--h2{
    /* font-size: 6rem; */
    font-size: calc(5vh + 5vw);
    font-family: var(-Font-family);
    font-weight: 600;
    /* margin-top: 30%; */
}

.about--text--p2{
    font-size: calc(1vh + 1vw);
    text-indent: 40px;
    text-align: start;
    text-align: justify;
}

.about--body{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.home-about{
    display: flex;
    width: 80%;
    height: 100%;
    margin-left: 10%;
    margin-top: 40%;
}

.--text--h1{
    /* margin-top: 10%; */
    font-size: calc(var(--font--size--head) + 1vw);
}

.--text--p{
    margin-top: 1rem;
    font-size: calc(var(--font--size--body) + .5vw);
    text-indent: 50px;
    text-align: justify;
}

.--text--btn{
    margin-top: 5%;
}

.about--resume{
    width: 100%;
}
.about--vision{
    width: 100%;
}

.about--symbol{
    width: 100%;
}

@media screen and (max-width: 960px) {

    .about-header{
        width: 100%;
        height: 50%;
        margin-top: 15%;
    
    
        display: flex;
        flex-direction: column;
    
        align-items: center;
        justify-content: center;
    
    }
    

    .about-header--image{
        width: 100%;
    }

    .about-header--image__image{
        width: 100%;
        margin-top: 5%;
    }
    .about--text{
        width: 90%;
        height: 100%;
    }
    .about--text--h2{
        display: none;
    }

    .about--text--p2{
        font-size: calc(2vh + 1vw);
        text-indent: 40px;
        text-align: start;
        text-align: justify;
    }
}
