
.service{
  width: 100vw;
  height: 100vh;

  /* background-color: #007aff; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.service__head{
  width: 90%;
  height: 30vh;
  /* background-color: burlywood; */
}

.service__body{
  width: 97%;
  height: max-content;

  margin-top: 5%;
  /* background-color: brown; */
}

.service--h1 {
  font-size: var(--font--size--head);
  color: var(--color--blue);
  margin-left: 2rem;
}

.text__p{
  width: 70%;
  height: fit-content;
  font-size: var(--font--size--body);
  margin-left: 1rem;
}
.p1{
  text-indent: 40px;
  text-align: justify;
}
.service--item{
  position: relative;
}

.mySwiper{
  width: 100%;
}
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
text-align: left;

/* Center slide text vertically */
display: -webkit-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-box-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-container {
  width: 100%;
  height: 300px;
  margin: 20px auto;
}
.append-buttons {
  text-align: center;
  margin-top: 20px;
}

.append-buttons button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #007aff;
  color: #007aff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 13px;
}

.card--item{
  width: 450px;
  height: 270px;
  margin: 2rem;
  padding: 2rem;
  cursor: pointer;
  background-image: url("../about/img/3Oho-App-logo.png");
  background-size: 98%;
}

.card--item:hover {
  background-color: #007bff38;
  color: #fff;
}

.card--item i:hover {
  color: #fff;
}


.fa-cash-register{
  font-size: var(--font--size--icon);
  justify-content: left;
  color: var(--color--icon);  
}
.fa-store{
  font-size: var(--font--size--icon);
  justify-content: left;
  color: var(--color--icon);  
}

.fa-people-roof{
  font-size: var(--font--size--icon);
  justify-content: left;
  color: var(--color--icon);  
}

.fa-warehouse-full{
  font-size: var(--font--size--icon);
  justify-content: left;
  color: var(--color--icon);  
}

.fa-browser{
  font-size: var(--font--size--icon);
  color: var(--color--icon);  
}

.fa-laptop-code{
  font-size: var(--font--size--icon);
  justify-content: left;
  color: var(--color--icon);  
}
.fa-desktop{
  font-size: var(--font--size--icon);
  justify-content: left;
  color: var(--color--icon);  
}

.fa-city{
  font-size: var(--font--size--icon);
  justify-content: left;
  color: var(--color--icon);  
}

.text--item1{
  width: 100%;
  font-size: var(--font--size--body);
  font-weight: bold;
  justify-content: left;
  justify-items: left;
  margin-top: 2rem;
  pointer-events: none;
}
.text--item1 text{
  color: var(--color--blue);
}
.text--item2{
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  align-items: flex-start;
  pointer-events: none;
}

.btn{
  top: 20%;
  width: 40%;
}


@media screen and (max-width: 960px) {
  .service__body{
    margin-top: calc(10vh+10vw);
  }
  .text__p{
    width: 100%;
  }
}



