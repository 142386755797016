.promotion{
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    margin-top: 10%;
    font-family: var(--Font-family);
}
.promotion__header{
    width: 100%;
    height: 50%;
    display: flex;

}

.promotion__header--h1{
  font-size:  var(--font--size--head);
}

.promotion__header--p{
  margin-top: 2%;
  font-size: var(--font--size--body);
}

.promotion--body{
  width: 100%;
  height: 80%;
 margin-bottom: 5%;
 display: flex;
 flex-direction: column;
}

.promotion--body--btn{
  width: 100%;
  height: 10vh;
  display: flex;
 justify-content: center;
}

.btn--buypos{
  width: max-content;
  height: max-content;

  border-radius:  2rem 0 0 0;
  background: var(--background--color-hover);
  color: '#ffffff';
  font-size: calc(2vh + 2vw);
  font-family: 'FC Iconic';
  padding-bottom: 1rem;

}
  