.Nav1{
    width: calc(100% - 15vw);
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 0.825px solid #9897979e;
    position: absolute;
    right: 0;
    z-index: 899;
}
.Nav2{
    width: calc(100% - 64px);
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 0.825px solid #9897979e;
    position: absolute;
    right: 0;
    z-index: 899;
}
.Nav_user{
    width: 89%;
    height: 100%;
    
    display: flex;
    justify-content: end;
    align-items: center;

    /* background-color: brown; */
}

.Nav_user_Name{
    width: 10%;
    height: 100%;
    font-size: large;
    font-weight: 600;

    /* background-color: burlywood; */

    display: flex;
    justify-content: start;
    align-items: center;


}
.Nav_logout{
    width: 10%;
    height: 100%;
    /* background-color: cadetblue; */

    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-new-border{
    border-radius: 10px;
}