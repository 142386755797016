:root {
  --text-color: #3a3a3a;
}
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap");
* {
  margin: 0;
  padding: 0;
  color: var(--text-color);
}

body {
  font-family: "FC Iconic", "Kanit";
  src: local('FC Iconic'), url("./font/FCIconicRegular.ttf") format("ttf");
  /* text-align: center; */
}

button {
  background-color: var(--color--blue);
  color: #f4f4f4;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 18px;
  font-weight: 500;
  border-radius: 7px;
  padding: 15px 35px;
  cursor: pointer;
  white-space: nowrap;
  margin: 10px;
}



@font-face {
  font-family: "FC Iconic", "Kanit";
  src: local('FC Iconic'), url("./font/FCIconicRegular.ttf") format("ttf");
}
