.homeAdmin{
    /* width: calc(100% - 80px); */
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    /* margin-left: 15vw; */
    position: relative;
    font-size: 1.5rem;
    background-color: rgba(211, 211, 212, 0.477);
    z-index: 800;
}


.homeAdmin_header{
    width: 90%;
    height: 20%;

    margin-top: 4%;

    display: flex;
    flex-direction: column;

    /* background-color: darksalmon; */
    font-size: 1.5rem;
}
.homeAdmin_header_I{
    width: 100%;
    height: 40%;

    margin-left: 2%;

    display: flex;
    font-size: 1.5rem;
    justify-content: left;
    align-items: center;

}
.btn_status{
    font-size: 2rem;
    border-radius: 40px;
}
.btn_status:hover{
    font-size: 2rem;
    border-radius: 40px;
    background-color: cornflowerblue;
}

#btn_status{
    font-size: 1.5rem;
    margin-right: 2%;
    border-radius: 40px;
    height: 3.5rem;
}
#btn_status:hover{
    font-size: 1.5rem;
    margin-right: 2%;
    background-color: cornflowerblue;
    height: 3.5rem;
}


.homeAdmin_header_II{
    width: 100%;
    height: 40%;
    margin-left: 2%;


    display: flex;
    font-size: 1.5rem;
    justify-content: left;
    align-items: center;

}
.homeAdmin_header_III{
    width: 100%;
    height: 50%;
    margin-right: 2%;

    display: flex;
    justify-content: end;
    align-items: center;
}

.searchData{
    width: 100%;
    height: 100%;


}

.homeAdmin_body{
    width: 90%;
    height: 60%;
    margin-top: 2%;
    display: flex;
}

.homeAdmin_bottom{
    width: 90%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

@media screen and (max-width: 960px) {

    .homeAdmin{
    margin-left: 82px;

    }
}