.exportexcel{
    width: 10rem;
    background-color: #1D6F42;
    color: aliceblue;
    font-family: var(--Font-family);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 2px solid #1D6F42;
    padding: 10px;
    border-radius: 5px;
}
.exportexcel:hover{
    background-color: #21A366;
}