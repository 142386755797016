.HomeManage{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.699);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
.HomeManage_body_left{
    width: 56.25rem;
    height: 90%;
    background: #fff;
    position: absolute;
    left: 5%;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 1.25rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}
.HomeManage_body_right{
    width: 40rem;
    height: 90%;
    background: #fff;
    position: absolute;
    right: 5%;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 1.25rem;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: start;

}

.HomeManage_header{
    width: 100%;
    height: 10%;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;

    font-size: 3rem;
}

.HomeManage_body_grad{
    width: 100%;

    display: flex;
    flex-direction: column;


}

.HomeManage_body_grad_right{
    width: 100%;
    margin-top: 12%;
    display: flex;
    flex-direction: column;
}

.HomeManage_body_grad_body{
    width: 90%;
    height: 10%;
    font-size: 2rem;
    display: flex;
    align-items: center;

    padding: 5px;

    position: relative;
}

.HomeManage_body_grad_body__title{
    width: 27%;
    height: 15%;
    margin-top: 2%;
}

.HomeManage_body_grad_body__titles{
    width: 27%;
    height: 15%;
    margin-top: 2%;
    color: rgb(255, 36, 36);
}

.HomeManage_body_grad_body_input{
    width: 70%;
    height: auto;
    font-size: 2.9rem;

}

.HomeManage_body_grad_body_select{
 width: 96%;
}

#HomeManage_body_grad_body_select{
    font-size: 1.3rem;
}

#body_select_option{
    font-size: 1.3rem;
}

.HomeManage_footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}

      
