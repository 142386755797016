.login{
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    background-color: rgba(155, 155, 155, 0.336);

    margin-top: 3rem;

    font-family: var(--Font-family);
    font-size: var(--font--size--body);
}

.login_body{
    width: 30%;
    height: 75%;


    border-radius: 2%;
    box-shadow: 0 12px 34px rgba(36, 34, 34, 0.329);

    background-color: rgba(255, 255, 255, 0.788);

    display: flex;
    flex-direction: column;

    justify-content: start;
    align-items: center;

}

.login_logo {
    width: 50%;
    max-height: 20%;

    margin-top: 5%;
}

.login_body__input{
    width: 80%;
    height:40%;
    margin-top:  2%;
    display: flex;
    flex-direction: column;
    margin-left: 0%;
}

.login_body_bottom{
    width: 80%;
    height: 20%;

    margin-top: 5%;

    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

}

@media screen and (max-width: 960px) {
    .login{
        width: 100%;
        height: 100%;

        margin-top: calc(8vh + 1.5vw);

        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
    }
    .login_body{
        width: 90%;
        height: 60vh;

        margin: 5%;
    }
}